<template>
  <EditorSizeSlider
    label="Grubość kreski:"
    :min="5"
    :max="18"
    :value.sync="internalValue"
  >
    <div slot="min-icon" class="circle small ml-2"></div>
    <div slot="max-icon" class="circle big"></div>
  </EditorSizeSlider>
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    EditorSizeSlider: () => import("@/components/editors/EditorSizeSlider"),
  },
};
</script>

<style scoped lang="scss">
.circle {
  border-radius: 50%;
  background-color: gray;
  &.small {
    height: 5px;
    min-height: 5px;
    width: 5px;
    min-width: 5px;
  }
  &.big {
    height: 18px;
    min-height: 18px;
    width: 18px;
    min-width: 18px;
  }
}
</style>